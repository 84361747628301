@font-face {
    font-family: "Arvo";   /*Can be any text*/
    src: local("Arvo"),
      url("./fonts/Arvo-Regular.ttf") format("truetype");
  }
@font-face {
    font-family: "Arvo-Bold";   /*Can be any text*/
    src: local("ArvoBold"),
      url("./fonts/Arvo-Bold.ttf") format("truetype");
  }
@font-face {
    font-family: "Arvo-BoldItalic";   /*Can be any text*/
    src: local("ArvoItalic"),
      url("./fonts/Arvo-BoldItalic.ttf") format("truetype");
  }
@font-face {
    font-family: "Arvo-Italic";   /*Can be any text*/
    src: local("ArvoItalic"),
      url("./fonts/Arvo-Italic.ttf") format("truetype");
  }

  /* LATO FONT*/

  @font-face {
    font-family: "Lato";   /*Can be any text*/
    src: local("Lato"),
      url("./fonts/Lato-Regular.ttf") format("truetype");
  }
@font-face {
    font-family: "Lato-Bold";   /*Can be any text*/
    src: local("LatoBold"),
      url("./fonts/Lato-Bold.ttf") format("truetype");
  }
@font-face {
    font-family: "Lato-Thin";   /*Can be any text*/
    src: local("LatoThin"),
      url("./fonts/Lato-Thin.ttf") format("truetype");
  }
@font-face {
    font-family: "Lato-Italic";   /*Can be any text*/
    src: local("LatoItalic"),
      url("./fonts/Lato-Italic.ttf") format("truetype");
  }

  /* Droid Sans FONT*/

  @font-face {
    font-family: "DroidSans";   /*Can be any text*/
    src: local("DroidSans"),
      url("./fonts/DroidSans.ttf") format("truetype");
  }
  @font-face {
    font-family: "DroidSans-Bold";   /*Can be any text*/
    src: local("DroidSansBold"),
      url("./fonts/DroidSans-Bold.ttf") format("truetype");
  }


    /* Droid Serif FONT*/

    @font-face {
        font-family: "DroidSerif";   /*Can be any text*/
        src: local("DroidSansRegular"),
          url("./fonts/DroidSerif-Regular.ttf") format("truetype");
      }
      @font-face {
        font-family: "DroidSerif-Bold";   /*Can be any text*/
        src: local("DroidSerifBold"),
          url("./fonts/DroidSerif-Bold.ttf") format("truetype");
      }
    

    /* Poppins */

    @font-face {
      font-family: "Poppins";   /*Can be any text*/
      src: local("Poppins"),
        url("./fonts/Poppins-Regular.ttf") format("truetype");
    }
    @font-face {
      font-family: "Poppins-Bold";   /*Can be any text*/
      src: local("PoppinsBold"),
        url("./fonts/Poppins-Bold.ttf") format("truetype");
    }
    @font-face {
      font-family: "Poppins-Black";   /*Can be any text*/
      src: local("PoppinsBlack"),
        url("./fonts/Poppins-Black.ttf") format("truetype");
    }
    
     /* Roboto */

     @font-face {
      font-family: "Roboto";   /*Can be any text*/
      src: local("Roboto"),
        url("./fonts/Roboto-Regular.ttf") format("truetype");
    }

    /* Inter */

    @font-face {
      font-family: "Inter";   /*Can be any text*/
      src: local("Inter"),
        url("./fonts/Inter-Regular.ttf") format("truetype");
    }
    @font-face {
      font-family: "Inter-Bold";   /*Can be any text*/
      src: local("InterBold"),
        url("./fonts/Inter-Bold.ttf") format("truetype");
    }